
import 'swiper/css';

/*--------------------
* Praise Section
----------------------*/
export default function Praise() {
    const praise_list = [
        {id:1, 'image':'/img/avatar-1.jpg', author_name:'Dr. Nicole Forsgren', position: <>Lead Author of <i>Accelerate</i> and Partner at Microsoft Research</>, description: <><i>Security Chaos Engineering</i> is a must read for technology leaders and engineers today, as we operate increasingly complex systems. SCE presents clear evidence that systems resilience is a shared goal of both ops and security teams, and showcases tools and frameworks to measure, design, and instrument systems to improve the resilience and security of our systems. 10/10 strong recommend (kidding but also not).</>},

        {id:2, 'image':'/img/avatar-2.jpg', author_name:'Bob Lord', position:'Bob Lord, Former Chief Security Officer of the DNC and former Chief Information Security Officer of Yahoo', description: "Shortridge weaves multiple under-served concepts into the book’s guidance, like recognizing human biases, the power of rehearsals, org design, complex systems, systems thinking, habits, design thinking, thinking like a product manager and a financial planner, and much more. This book brings the reader in on a well-kept secret: security is more about people and processes than about technology. It is our mental models of those elements that drive our efforts and outcomes."},

        {id:3, 'image':'/img/avatar-3.jpg', author_name:'Phil Venables', position:'Chief Information Security Officer, Google Cloud', description: "As our societies become more digitized then our software ecosystems are becoming ever more complex. While complexity can be considered the enemy of security, striving for simplicity as the sole tactic is not realistic. Rather, we need to manage complexity and a big part of that is chaos engineering. That is testing, probing, modeling and nudging complex systems to a better state. This is tough but Kelly and Aaron bring immense cross-domain, practical real world experience to this area in a way that all security professionals should find accessible and fascinating."},

        {id:4, 'image':'/img/avatar-4.jpg', author_name:'Camille Fournier', position:<>Engineering leader and author, <i>The Manager’s Path</i></>, description: <><i>Security Chaos Engineering</i> provides a much-needed reframing of cybersecurity that moves it away from arcane rules and rituals, replacing them with modern concepts from software and resiliency engineering. If you are looking for ways to uplift your security approaches and engage your whole engineering team in the process, this book is for you.</>},

        {id:5, 'image':'/img/avatar-5.jpg', author_name:'Rob Duhart, Jr.', position:'VP, Deputy CISO and CISO eCommerce at Walmart', description: "We as defenders owe it to ourselves to make life as hard for attackers as possible. This essential work expertly frames this journey succinctly and clearly and is a must read for all technology leaders and security practitioners, especially in our cloud native world."},

        {id:6, 'image':'/img/avatar-6.jpg', author_name:'George Neville-Neil', position:<>Author of the Kode Vicious column in <i>ACM Queue Magazine</i></>, description: <><i>Security Chaos Engineering</i> is an unflinching look at how systems are secured in the real world. Shortridge understands both the human and the technical elements in security engineering.</>},

        {id:7, 'image':'/img/avatar-7.jpg', author_name:'Thomas Dullien', position:'Founder, security researcher, and performance engineer', description: <>Security masquerades as a technical problem, but it really cuts across all layers: organizational, cultural, managerial, temporal, historical, and technical. You can't even define security without thinking about human expectations, and the dividing line between "flaw" and "vulnerability" is non-technical. This thought-provoking book  emphasizes the inherent complexity of security and the need for flexible and adaptive approaches that avoid both box-ticking and 0day-worship.</>},
    ]

    return (
        <>
        <section className="section testimonial-section" data-scroll-data="4" id="praise">
            <div className="container">
                <div className="row section-heading justify-content-center">
                    <div className="col-lg-6 text-center">
                        <h3><span>Praise</span></h3>
                    </div>
                </div>
                <div className="row">
                {
                    praise_list.map((val ,i) =>{
                        return <div key={i} className="col-md-6">
                                    <div className="feature-box-03">
                                        <div className="feature-img">
                                            <img src={val.image}  title={"A photo of " + val.author_name} alt={"A photo of " + val.author_name}/>
                                        </div>
                                        <div className="feature-content">
                                            <div className="icons">
                                                <i className="fas fa-quote-left"></i>
                                            </div>
                                            <p>{ val.description }</p>
                                            <h6>{ val.author_name }</h6>
                                            <span>{ val.position }</span>
                                        </div>
                                    </div>
                                </div>
                            })
                }
                </div>
            </div>
        </section>
        </>
    );
}
