
import ReactTypingEffect from 'react-typing-effect';

/*--------------------
* Banner Section
----------------------*/
export default function Banner() {
    return (
        <>
            <section data-scroll-data="0" id="home" className="home-section bg-gray">
                <div className="container">
                    <div className="row gy-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="home-intro one-page-nav text-center text-lg-start">
                                <h1>Sustaining Resilience in Software and Systems</h1>
                                <h2>Learn about <ReactTypingEffect text={["Software Resilience", "Chaos Experiments", "Modern Security", "Software Quality", "Reliability", "Systems Security"]}
                                    speed="80" typingDelay="10" eraseDelay="2000" eraseSpeed="40" /> </h2>
                                <p>A book by Kelly Shortridge with case studies by Aaron Rinehart</p>
                                <div className="btn-bar">
                                    <a className="px-btn px-btn-theme" href="https://www.amazon.com/Security-Chaos-Engineering-Sustaining-Resilience/dp/1098113829">Order Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0">
                            <div className="home-image text-center">
                                <img src="img/security-chaos-engineering-book-cover.jpg" title="Book Cover" alt="The cover of the book with a large drawing of an ornate hawk eagle. The title is Security Chaos Engineering Sustaining Resilience in Software and Systems."/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
