
import { Link } from "react-scroll";

/*--------------------
* About Section
----------------------*/
export default function About() {
    return (
        <>
            <section className="section about-section" data-scroll-data="1" id="about">
                <div className="container">
                    <div className="row gy-5 align-items-center">
                        <div className="col-lg-5 text-center">
                            <img src="img/Chaos_kitty_Beakers.png" title="An illustration of Chaos Kitty by Savannah Glitschka" alt="An illustration of Chaos Kitty by Savannah Glitschka. Chaos Kitty is holding two bubbling beakers, one with pink fluid and the other with blue fluid, and admiring them with wonderment. Chaos Kitty wears a wizard's robe and hat in shades of periwinkle."/>
                        </div>
                        <div className="col-lg-7 ps-lg-5">
                            <div className="about-text">
                                <h3 className="h1">About the Security Chaos Engineering book</h3>
                                <p>Cybersecurity is broken. Year after year, attackers remain unchallenged and undeterred, while engineering teams feel pressure to design, build, and operate “secure” systems. Failure can’t be prevented, mental models of systems are incomplete, and our digital world constantly evolves. How can we verify that our systems behave the way we expect? What can we do to improve our systems’ resilience?</p>
                                <p>In this comprehensive guide, Kelly Shortridge helps you navigate the challenges of sustaining resilience in complex software systems by using the principles and practices of security chaos engineering. By preparing for adverse events, you can ensure they don’t disrupt your ability to innovate, move quickly, and achieve your engineering and business goals.</p>
                                <p>
                                    <ul>
                                        <li>Learn how to design a modern security program</li>
                                        <li>Make informed decisions at each phase of software delivery to nurture resilience and adaptive capacity</li>
                                        <li>Understand the complex systems dynamics upon which resilience outcomes depend</li>
                                        <li>Navigate technical and organizational trade-offs that distort decision making in systems</li>
                                        <li>Explore chaos experimentation to verify critical assumptions about software quality and security</li>
                                        <li>Learn how major enterprises leverage security chaos engineering</li>
                                    </ul>
                                </p>
                                <div className="row pt-2">
                                    <div className="col-4 col-md-4">
                                        <h5>350+</h5>
                                        <span>Pages of Pragmatic Opportunities</span>
                                    </div>
                                    <div className="col-4 col-md-4">
                                        <h5>6</h5>
                                        <span>In-depth Enterprise Case Studies</span>
                                    </div>
                                    <div className="col-4 col-md-4">
                                        <h5>1</h5>
                                        <span>Adorable Chaos Kitty to guide you on your quest</span>
                                    </div>
                                </div>
                                <div className="btn-bar pt-4">
                                    <Link className="px-btn px-btn-theme me-4" href="https://www.amazon.com/Security-Chaos-Engineering-Sustaining-Resilience/dp/1098113829">Order Now</Link>
                                    <Link className="px-btn px-btn-dark" href="https://www.amazon.com/Security-Chaos-Engineering-Kelly-Shortridge-ebook/dp/B0C122N5GQ/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=&sr=">Read on Kindle</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
