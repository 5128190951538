
import { Link } from "react-scroll";

/*--------------------
* Header Menu
----------------------*/
function Menu(props){
    return <li><Link className="nav-link" data-scroll-nav={props.id}  smooth={true} to={props.link}><span>{props.name}</span></Link></li>;
}

/*--------------------
* Header
----------------------*/
export default function Header() {
    const header_menus = [
        { id:0, name:'Home', scroll_link:'home' },
        { id:1, name:'Book Overview', scroll_link:'about' },
        { id:2, name:'Contents', scroll_link:'contents' },
        { id:3, name:'Praise', scroll_link:'praise' },
        { id:4, name:'Where to Buy', scroll_link:'order' },
        { id:5, name:'Subscribe', scroll_link:'subscribe' }
    ]
    return (
        <>
            <header className="main-header">
                <nav className="navbar header-nav navbar-expand-lg one-page-nav">
                    <div className="container">
                        <div className="collapse navbar-collapse justify-content-end" id="navbar-collapse-toggle">
                            <ul className="navbar-nav mx-auto">
                                {
                                    header_menus.map((val, i) => {
                                        return <Menu key={i} id={val.id} name={val.name} link={val.scroll_link}/>
                                    })
                                }
                            </ul>
                        </div>
                        <div className="ms-auto d-none d-lg-block">
                            <a className="px-btn px-btn-theme" smooth={true} href="https://www.amazon.com/Security-Chaos-Engineering-Sustaining-Resilience/dp/1098113829">Order Now</a>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
