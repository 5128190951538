
/*--------------------
* Authors Section
----------------------*/
export default function Authors() {
    return (
        <>
            <section data-scroll-data="6" id="authors" className="section work-section bg-gray">
                <div className="container">
                    <div className="row section-heading justify-content-center">
                        <div className="col-lg-6 text-center">
                            <h3><span>The Authors</span></h3>
                        </div>
                    </div>
                    <div className="lightbox-gallery portfolio-box">`
                        <div className="row gx-3 pb-3 mb-5 pb-lg-3 mb-lg-3 gy-4 portfolio-box justify-content-around align-items-center">
                            <div className="col-md-6 col-lg-5">
                                <div className="portfolio-img">
                                    {/*eslint-disable-next-line*/}
                                    <img src="img/kelly-bio-color.JPG" title="Kelly Shortridge" alt="A photo of Kelly Shortridge wearing a black tshirt and black blazer. They have long blonde hair and blue eyes."/>
                                    <a href="https://kellyshortridge.com/" className="gallery-link gallery-link-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5">
                                <div className="portfolio-text">
                                    <h6><span>Lead Author</span></h6>
                                    <h4>Kelly Shortridge</h4>
                                    <p>Kelly Shortridge is a senior principal engineer at Fastly in the office of the CTO. Shortridge is best known for their work on resilience in complex software systems, the application of behavioral economics to cybersecurity, and bringing security out of the dark ages. Shortridge has been a successful enterprise product leader as well as a startup founder (with an exit to CrowdStrike) and investment banker. Shortridge frequently advises Fortune 500s, investors, startups, and federal agencies and has spoken at major technology conferences internationally, including Black Hat USA, O’Reilly Velocity Conference, and SREcon. Shortridge’s research has been featured in ACM, IEEE, and USENIX, spanning behavioral science in cybersecurity, deception strategies, and the ROI of software resilience. They also serve on the editorial board of <i>ACM Queue</i>.</p>
                                    <div className="btn-bar">
                                        <a href="https://kellyshortridge.com/" className="px-btn px-btn-theme" data-scroll-nav="4">Personal Site</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-3 pb-3 mb-5 pb-lg-3 mb-lg-3 gy-4 portfolio-box justify-content-around align-items-center flex-row-reverse">
                            <div className="col-md-6 col-lg-5">
                                <div className="portfolio-img">
                                    {/*eslint-disable-next-line*/}
                                    <img src="img/aaron-rinehart.jpg" title="Aaron Rinehart" alt="A photo of Aaron Rinehart. He is wearing a teal curly wig with oversized teal glasses. Aaron has his hands up and wide, as well as a wide smile, as if saying hell yeah."/>
                                    <a href="https://www.linkedin.com/in/aaronsrinehart" className="gallery-link gallery-link-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5">
                                <div className="portfolio-text">
                                    <h6><span>Contributing Author</span></h6>
                                    <h4>Aaron Rinehart</h4>
                                    <p>Aaron Rinehart is a senior distinguished engineer, SRE and Chaos Engineering at Capital One. He has spent his career solving complex engineering problems and transforming cybersecurity practices across a diverse set of industries: healthcare, insurance, government, aerospace, technology, higher education, and the military. He began shaping the application of chaos engineering within cybersecurity during his tenure as the Chief Security Architect at a Fortune 4, UnitedHealth Group (UHG).</p>
                                    <div className="btn-bar">
                                        <a href="https://www.linkedin.com/in/aaronsrinehart" className="px-btn px-btn-theme" data-scroll-nav="4" >LinkedIn</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}
