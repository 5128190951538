
/*--------------------
* Footer Section
----------------------*/
function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 py-2">
                        <div className="nav justify-content-center justify-content-md-start">
                            <a href="https://twitter.com/swagitda_/"><i className="fab fa-twitter"></i></a>
                            <a href="https://www.linkedin.com/in/kellyshortridge"><i className="fab fa-linkedin-in"></i></a>
                            <a href="https://hachyderm.io/@shortridge"><i className="fab fa-mastodon"></i></a>
                            <a href="https://www.instagram.com/voidelfprince/"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div className="col-md-6 py-2 text-center text-md-end">
                        <p className="m-0">© 2023 Kelly Shortridge all rights reserved</p>
                    </div>
                </div>
            </div>
        </footer>
     );
}

export default Footer;