
import Header from "../Header/header"
import Banner from "../Banner/banner"
import CaseStudies from "../CaseStudies/case-studies"
import About from "../About/about"
import Contents from "../Contents/contents"
import Order from "../Order/order"
import Authors from "../Authors/authors"
import Praise from "../Praise/praise"
import Contact from "../Contact/contact"
import Footer from "../Footer/footer"

/*--------------------
* Building Krinky
----------------------*/
export default function Krinky() {
    return (
        <>
            <Header/>
                <main className="wrapper">
                    <Banner/>
                    <About/>
                    <Contents/>
                    <CaseStudies/>
                    <Praise/>
                    <Order/>
                    <Authors/>
                    <Contact/>
                </main>
            <Footer/>
        </>
    )
}
