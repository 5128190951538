
/*--------------------
* Contact Section
----------------------*/

export default function Contact() {
    return (
        <>
        <section data-scroll-data="4" id="contactus" className="section contactus-section bg-dark" style={{ "backgroundImage": "url(/img/effect/bg-effect-3.png)", "backgroundRepeat": "no-repeat", "backgroundSize": "cover", "backgroundPosition": "center"}}>
            <div className="container">
                <div className="row gy-5">
                    <div>
                        <div className="contact-form">
                            <h6>Subscribe for Updates</h6>
                            <p className="lead">Sign up to get the latest updates on this book, book signings, upcoming talks, and new blog posts delivered to your inbox.</p>
                            <div>
                                <div className="send">
                                    <a href="http://eepurl.com/ioB-QM">
                                    <button className="px-btn px-btn-theme2" type="button">Subscribe</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        </>
     );
}
