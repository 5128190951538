
import 'swiper/css';

/*--------------------
* Case Studies
----------------------*/
function CaseStudy(props){
    return(
        <>
            <div className="col-md-2 text-center justify-content-center align-items-center">
                <img src={props.brand_icon} title={props.org_name} alt={props.org_name}/>
            </div>
        </>
    )
}

/*--------------------
* Case Study Slider
----------------------*/
export default function CaseStudySlider() {
    const CaseStudy_list = [
        { id:1, brand_icon: '/img/uhc-logo.png', org_name: 'United Health Group'},
        { id:2, brand_icon: '/img/verizon.svg', org_name: 'Verizon'},
        { id:3, brand_icon: '/img/opendoor.svg', org_name: 'OpenDoor'},
        { id:4, brand_icon: '/img/cardinal-health.svg', org_name: 'Cardinal Health'},
        { id:5, brand_icon: '/img/accenture.svg', org_name: 'Accenture Global'},
        { id:6, brand_icon: '/img/capital-one.svg', org_name: 'CapitalOne'},
        
    ]

    return (
        <>
            <div className="bg-light py-3 py-md-4 text-center align-items-center" data-scroll-data="3" id="casestudies">
                <div className="container align-items-center">
                    <h2>Plus Chapter 9 with Case Studies by</h2>
{/*                    <div className="d-inline-flex">
                        {
                            CaseStudy_list.map((val, i)=>{
                              return <SwiperSlide key={i}><CaseStudy brand_icon={val.brand_icon}/></SwiperSlide>
                            })
                        }
                    </div>*/}
                    <div className="row gy-4 d-inline-flex align-items-center">
                       {/*<Swiper spaceBetween={10} slidesPerView={6}>*/} 
                            {
                                CaseStudy_list.map((val, i)=>{
                                  return <CaseStudy brand_icon={val.brand_icon}/>
                                })
                            }
                        {/*</Swiper>*/}
                    </div>
                </div>
            </div>
        </>
    )
}
