
/*--------------------
* Order Section
----------------------*/
function Order(props) {
    return (
        <>
            <a className="col-6 col-md-4 col-lg-6" href={props.href}>
                <div className={props.id + " feature-box-02 "}>
                    <div className="icon">
                        <i className={props.fontAwesomeIcon}></i>
                    </div>
                    <h6>{props.title}</h6>
                </div>
            </a>
        </>
    );
}

/*--------------------
* Order List Section
----------------------*/
export default function OrderList() {

    const order_list = [
        {id:'bg-1', title:'Amazon + Kindle', href: "https://www.amazon.com/Security-Chaos-Engineering-Sustaining-Resilience/dp/1098113829", fontAwesomeIcon: "fab fa-amazon" },
        {id:'bg-2', title:'Bookshop', href:"https://bookshop.org/p/books/security-chaos-engineering-developing-resilience-and-safety-at-speed-and-scale-aaron-rinehart/18793471", fontAwesomeIcon: "fas fa-book" },
        {id:'bg-3', title:'Barnes & Noble', href:"https://www.barnesandnoble.com/w/security-chaos-engineering-kelly-shortridge/1143048346", fontAwesomeIcon: "fas fa-book-open" },
        {id:'bg-4', title:'Target', href:"https://www.target.com/p/security-chaos-engineering-by-kelly-shortridge-aaron-rinehart-paperback/-/A-87566932", fontAwesomeIcon: "fas fa-bullseye" },
        {id:'bg-5', title:'Walmart', href:"https://www.walmart.com/ip/Security-Chaos-Engineering-Sustaining-Resilience-in-Software-and-Systems-Paperback-9781098113827/1169169327", fontAwesomeIcon: "fas fa-asterisk" },
        {id:'bg-6', title:'O\'Reilly (eBook)', fontAwesomeIcon: "fas fa-globe" },
    ]

    return (
        <>
            <section data-scroll-data="5" id="order" className="section experience-section pb-0">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-6">
                            <div className="section-heading">
                                <h3 className="m-0"><span>Where to Buy</span></h3>
                            </div>
                            <div className="skill-box">
                                <div className="row g-3">
                                    {
                                        order_list.map((val, i)=>{
                                            return <Order key={i} id={val.id} title={val.title} href={val.href} fontAwesomeIcon={val.fontAwesomeIcon}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 text-center">
                            <img src="img/Chaos_kitty_Sip.png" title="An illustration of Chaos Kitty by Savannah Glitschka" alt="An illustration of Chaos Kitty by Savannah Glitschka. Chaos Kitty is holding a mug of hot chocolate in their paws with a sly grin on their face. Chaos Kitty wears a wizard's robe and hat in shades of periwinkle."/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
