

/*--------------------
* Chapters Section
----------------------*/
function Content(props) {
    return (
        <>
            <div className="col-md-6">
                <div className={props.id + " feature-box-01"}>
                    <div className="icon"><i className={"bi bi-"+props.icon}></i></div>
                    <div className="feature-content">
                        <h5>{props.title}</h5>
                        <p>{props.description}</p>
                    </div>
                </div>
            </div>
        </>

    );
}

/*--------------------
* Chapters List Section
----------------------*/
export default function ChapterList() {
    const chapter_list = [
        {id:'bg-1', title:'Resilience in Software and Systems', description:'We begin our journey in Chapter 1 by discussing resilience in complex systems, how failure manifests, how resilience is maintained, and how we can avoid common myths that lead our security strategy astray.', icon:"1-circle"},
        {id:'bg-2', title:'Systems-Oriented Security', description:'In Chapter 2, we explore the shift toward systems thinking in security, describing how to refine mental models of systems behavior and perform resilience assessments before comparing SCE to traditional cybersecurity.', icon:"2-circle"},
        {id:'bg-3', title:'Architecting and Designing', description:'Chapter 3 starts in the “first” phase of software delivery: architecting and designing systems. We think through how to invest effort based on your organization’s specific context before describing opportunities to invest in looser coupling and linearity.',icon:"3-circle"},
        {id:'bg-4', title:'Building and Delivering', description:'In Chapter 4, we map the five features that define resilience to activities we can pursue when developing, building, testing, and delivering systems. The ground we cover is expansive; this chapter is perhaps the most packed full of practical wisdom.',icon:"4-circle"},
        {id:'bg-5', title:'Operating and Observing', description:'Chapter 5 describes how we can sustain resilience as our systems run in production. We reveal the overlap between SRE and security goals, then discover different strategies for security observability before closing with a discussion of scalability’s relevance to security.', icon:"5-circle"},
        {id:'bg-6', title:'Responding and Recovering', description:'In Chapter 6, we dig into the biases that can distort our decision making and learning during incident response and recovery. Along the way, we propose tactics for countering those biases and supporting more constructive efforts that eradicate blame games around "human error".', icon:"6-circle"},
        {id:'bg-7', title:'Platform Resilience Engineering', description:'Chapter 7 introduces the concept of platform resilience engineering and describes how to implement it in practice within any organization. We cover the process for creating security solutions for internal customers (like engineering teams); the Ice Cream Cone Hierarchy of Security Solutions is especially tasty wisdom.', icon:"7-circle"},
        {id:'bg-8', title:'Security Chaos Experiments', description:'In Chapter 8 we learn how to conduct experiments and paint a richer picture of our systems, which in turn helps us better navigate strategies to make them more resilient to failure. We outline the end-to-end experimentation process including setup, hypotheses, experiment design, running experiments, and analyzing evidence.', icon:"8-circle"},    ]
    return (
        <>
           <section data-scroll-data="2" id="contents" className="section services-section bg-gray" style={{ "backgroundImage": "url(/img/effect/bg-effect-1.png)", "backgroundRepeat": "no-repeat", "backgroundSize": "cover"}}>
                <div className="container">
                    <div className="row section-heading justify-content-center">
                        <div className="col-lg-6 text-center">
                            <h3><span>Contents</span></h3>
                        </div>
                    </div>
                    <div className="row gy-4">
                        {
                            chapter_list.map((val, i)=>{
                                return <Content key={i} id={val.id} title={val.title} description={val.description} icon={val.icon} />
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    );
}
